export type UserPermission = string[];
export interface AuthParams {
  requiredPermissions?: string[];
  oneOfPerm?: boolean;
}

export default (params: AuthParams, userPermission: UserPermission) => {
  const { requiredPermissions, oneOfPerm } = params;
  if (Array.isArray(requiredPermissions) && requiredPermissions.length) {
    let count = 0;
    for (const rp of requiredPermissions) {
      if (userPermission.includes(rp) || userPermission.includes('*')) {
        count++;
      }
    }
    return oneOfPerm ? count > 0 : count === requiredPermissions.length;
  }
  return true;
};
