import React, { useMemo } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Layout, Menu, Spin } from '@arco-design/web-react';
import { useSelector } from 'react-redux';
import useRoute from '@/routes';
import getUrlParams from './utils/getUrlParams';
import lazyload from './utils/lazyload';
import { GlobalState } from './store';
import '@/style/layout.module.less';
import FeedbackModal from './components/FeedbackModal';

const Content = Layout.Content;

function getFlattenRoutes(routes) {
  const res = [];
  function travel(_routes) {
    _routes.forEach((route) => {
      const visibleChildren = (route.children || []).filter(
        (child) => !child.ignore
      );
      if (route.key && (!route.children || !visibleChildren.length)) {
        try {
          route.component = lazyload(() => import(`./pages/${route.key}`));
          res.push(route);
        } catch (e) {
          console.error(e);
        }
      }
      if (route.children && route.children.length) {
        travel(route.children);
      }
    });
  }
  travel(routes);
  return res;
}

function PageLayout() {
  const { userInfo } = useSelector((state: GlobalState) => state);

  const [routes, defaultRoute] = useRoute(userInfo?.permissions);

  const flattenRoutes = useMemo(() => getFlattenRoutes(routes) || [], [routes]);

  return (
    <div className="app">
      <FeedbackModal />
      <Switch>
        {flattenRoutes.map((route, index) => {
          return (
            <Route
              key={index}
              path={`/${route.key}`}
              component={route.component}
            />
          );
        })}
        <Route exact path="/">
          <Redirect to={`/${defaultRoute}`} />
        </Route>
        <Route
          path="*"
          component={lazyload(() => import('./pages/exception/403'))}
        />
      </Switch>
    </div>
  );
}

export default PageLayout;
