import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Divider,
  Empty,
  Grid,
  Image,
  List,
  Modal,
  Space,
  Tag,
  Typography,
} from '@arco-design/web-react';
import { GlobalState } from '@/store';
import './styles/FeedbackModal.less';
import { useSelector, useDispatch } from 'react-redux';
import request from '@/request/request';
import Loading from './Loading';
const { Row, Col } = Grid;
const FeedbackModal = () => {
  const feedbackVisible = useSelector((state) => state.feedbackVisible);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);

  const loadList = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const response = await request('/resourceFeedback/list', {
        data: {},
        method: 'get',
      });
      setList(response.data.list);
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    feedbackVisible && loadList();
  }, [feedbackVisible]);

  return (
    <Modal
      title={'资源反馈'}
      visible={feedbackVisible}
      footer={null}
      style={{ width: '340px', height: '80vh' }}
      unmountOnExit
      maskClosable={true}
      focusLock={false}
      escToExit={false}
      onCancel={() => {
        dispatch({
          type: 'update-feedback-visible',
          payload: { feedbackVisible: false },
        });
      }}
    >
      <div className="feedback--content">
        {loading ? (
          <Loading minHeight="60vh" />
        ) : list.length > 0 ? (
          list.map((item, index) => {
            return (
              <div className="feedback--content--card" key={index}>
                <div className="feedback--content--card__title">
                  {item.content}
                </div>
                <div className="feedback--content--card__meta">
                  <div className="time">{item.createdAt}</div>
                  <div
                    className="status"
                    style={{
                      color: ['', '', 'rgb(var(--green-6))', '#f50'][
                        item.status
                      ],
                    }}
                  >
                    {['', '待处理', '已处理', '已忽略'][item.status]}
                  </div>
                </div>
                {item.reply && (
                  <div className="feedback--content--card__reply">
                    <div className="feedback--content--card__reply--content">
                      {item.reply || '已处理'}
                    </div>
                    <div className="feedback--content--card__reply--meta">
                      {item.updatedAt}
                    </div>
                  </div>
                )}
              </div>
            );
          })
        ) : (
          <Empty />
        )}
      </div>
    </Modal>
  );
};

export default FeedbackModal;
