import { Spin } from '@arco-design/web-react';
import React from 'react';
const Loading = (props: { minHeight?: string }) => {
  return (
    <div
      style={{
        minHeight: props.minHeight || '87vh',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Spin loading={true} tip="加载中.." />
    </div>
  );
};

export default Loading;
